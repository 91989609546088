/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */


/* Set font family */
body {
  font-family: "Work Sans", sans-serif;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}
/* You can include Tailwind CSS directives here if you are using Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;




* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 1px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 10px;
  border: 2px solid var(--primary);
}


:root {
  --primary: rgb(255, 255, 255);
  --secondary: rgb(33, 59, 201);
}

*::-webkit-scrollbar:hover {
  background-color: rgb(0, 0, 0);
  width: 12px;
}

/* Change secondary color to gray on hover */
*::-webkit-scrollbar-thumb:hover {
  width: 12px;
}