/* #app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.paginationButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    list-style: none;
  }
  
  .paginationButtons > li {
    margin-left: 10px;
    border: 1px solid #000;
    border-radius: 6px;
    padding: 5px 10px;
  }

/* Customize pagination */
.swiper-pagination-fraction {
    color: white; /* Change the color of the pagination text to white */
  }

  .mySwiper {
   /* background-color: #333;  /*Change the background color of the slider container (you can adjust the color as needed) */
    position: relative; /* Ensure the navigation buttons are positioned correctly */
  }
  
  /* Customize navigation buttons */
/* Customize navigation buttons */
/* Customize navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  background-color: white; /* Change the background color of the navigation buttons to white */
  padding: 10px;
  color: black; /* Change the color of the navigation button icons */
  position: absolute; /* Position absolutely within the container */
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  right: 20px; /* Adjust the distance from the right as needed */
}

/* Hover effect for navigation buttons */
.swiper-button-next:hover,
.swiper-button-prev:hover {
  /*background-color: #333; /* Change the background color on hover (you can adjust the color as needed) */
  color: white; /* Change the color of the navigation button icons on hover */
}